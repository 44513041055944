<template>
	<div>
		<el-header class="layout-header center" height="40px">
			<div class="logo">设宇宙-AI创作平台</div>
			<div class="flex">
				<el-button class="btn-package" size="mini" round type="primary" plain @click="showPayMeal">
					<i class="el-icon-s-ticket"></i> 购买套餐
				</el-button>
				<el-button class="btn-package" size="mini" round type="primary" plain @click="showVipDialog">
					<i class="el-icon-medal"></i> 购买会员
				</el-button>
				<el-dropdown trigger="click" class="user-dropdown" v-show="session">
					<span class="el-dropdown-link flex-center">
						<el-image
							class="header-img"
							title="点击修改头像"
							v-show="session.headImgUrl"
							:src="session.headImgUrl"
						>
							<div slot="placeholder">.</div>
						</el-image>
						<div class="ml10 user-name">{{ session.nickname }}</div>
					</span>

					<el-dropdown-menu slot="dropdown" class="user-center-menu">
						<div class="flex-center mt10">
							<div>
								<el-image
									class="pointer header-img-large"
									:src="session.headImgUrl"
									title="点击修改头像"
								></el-image>
								<a @click="showModifyUser" class="btn-edit" title="修改昵称和头像">
									<i class="el-icon-edit"></i>
								</a>
							</div>
						</div>
						<el-dropdown-item class="flex-between">
							<div class="font-bolder">我的昵称</div>
							<div class="flex-center">
								<div>{{ session.nickname }}</div>
							</div>
						</el-dropdown-item>
						<el-dropdown-item class="flex-between">
							<div class="font-bolder">
								助理算力
								<!-- <el-button @click="showPay" class="pay" type="primary" round
									><i class="el-icon-bank-card" />
									充值
								</el-button> -->
							</div>
							<div class="flex-between">
								<div>{{ session.chat_count }} 算力</div>
							</div>
						</el-dropdown-item>
						<el-dropdown-item>
							<div class="font-bolder">联系客服</div>
						</el-dropdown-item>
						<el-dropdown-item class="flex-between" v-if="isVip">
							<div class="font-bolder">会员权益生效中</div>
							<div class="flex-between">
								<el-button @click="onGotoBigvip" class="pay" type="primary" round>权益中心</el-button>
							</div>
						</el-dropdown-item>
						<el-dropdown-item class="logout" @click.native="logout">
							<el-button type="danger" round>退出登录</el-button>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>

			<!-- 修改个人 -->
			<el-dialog
				id="modify-user"
				title="修改个人信息"
				:append-to-body="true"
				:visible.sync="modifyDialogVisible"
				width="30%"
			>
				<div class="flex-center">
					<el-upload
						class="avatar-uploader"
						action="https://ai-v2.deepcity.cn/user/api.userinfo/uploadImg"
						:show-file-list="false"
						name="img"
						:on-success="handleAvatarSuccess"
						:before-upload="beforeAvatarUpload"
					>
						<div class="relative">
							<el-image class="header-img" title="点击上传头像" :src="modifyHeaderImg"></el-image>
							<div class="uploading flex-center" v-show="uploading">
								<i class="spin el-icon-loading"></i>
							</div>
							<a class="btn-edit" title="点击上传头像">
								<i class="el-icon-upload2"></i>
							</a>
						</div>
					</el-upload>
				</div>

				<div class="flex-center mt20">
					<el-input style="width: 60%" v-model="modifyNickname" placeholder="请输入昵称"></el-input>
				</div>

				<span slot="footer" class="dialog-footer">
					<el-button @click="modifyDialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="submitModifyUserInfo">提交</el-button>
				</span>
			</el-dialog>

			<el-dialog id="pay-user" title="支付" :append-to-body="true" :visible.sync="payDialogVisible" width="30%">
				<div class="flex-center mb40">
					<el-image :src="payImage" style="width: 200px"></el-image>
				</div>

				<el-alert center title="请打开小程序扫码充值" type="success" :closable="false"></el-alert>

				<!--            <span slot="footer" class="dialog-footer">-->
				<!--                <el-button @click="payDialogVisible = false">取 消</el-button>-->
				<!--                <el-button type="primary" @click="submitModifyUserInfo">提交</el-button>-->
				<!--            </span>-->
			</el-dialog>
		</el-header>

		<pay-meal-dialog ref="paymeal" @onBuyMeal="onBuyMeal"></pay-meal-dialog>
		<pay-vip-dialog ref="vipDialogRef" @onBuyVip="onBuyVip"></pay-vip-dialog>
	</div>
</template>

<script>
import { getSession, getToken, removeToken } from '@/utils/auth';
import { headerImg } from '@/config';
import { getUserInfo, updateUserInfo, lunciHelpInfo, joinHelpLunci, getPuserVipInfoApi } from '@/apis/user';
// import { getPayCode } from '@/apis/user';
import PayMealDialog from '@/views/app/dialogs/pay-meal-dialog.vue';
import PayVipDialog from '@/views/app/dialogs/pay-vip-dialog.vue';

export default {
	name: 'layout-header',
	components: { PayMealDialog, PayVipDialog },

	async mounted() {
		let yqGuid = localStorage.getItem('yqGuid');
		if (yqGuid) {
			this.showYQConfirm(yqGuid);
		}
		this.getUserVipInfo();
		await getUserInfo();
		this.session = getSession();
		// this.payImage = await getPayCode();
	},

	data() {
		let token = getToken();
		return {
			modifyNickname: '',
			modifyHeaderImg: '',
			uploading: false,
			session: {},
			token,
			headerImg,
			modifyDialogVisible: false,
			payDialogVisible: false,
			payImage: '',
			isVip: false,
		};
	},

	methods: {
		async getUserVipInfo() {
			let res = await getPuserVipInfoApi();
			this.isVip = res.data.isVip;
		},
		onBuyVip() {
			this.getUserVipInfo();
		},
		async onBuyMeal() {
			await getUserInfo();
			this.session = getSession();
		},
		onGotoBigvip() {
			this.$router.push('/app/bigvip');
		},
		showPayMeal() {
			this.$refs.paymeal.show();
		},
		showVipDialog() {
			this.$refs.vipDialogRef.show();
		},
		async showYQConfirm(guid) {
			let res = await lunciHelpInfo({ chatLunciGuid: guid });
			if (res.code !== 0) {
				this.$message({
					type: 'info',
					message: res.msg || '获取协作聊天失败',
				});
				localStorage.setItem('yqGuid', '');
				return;
			}
			let title = `用户${res.data.lunciUser}邀请您为他协作${res.data.lunciName},是否接受。`;
			this.$confirm(title, '协作邀请', {
				confirmButtonText: '接收',
				cancelButtonText: '拒绝',
			})
				.then(async () => {
					await joinHelpLunci({ chatLunciGuid: guid });
					localStorage.setItem('yqGuid', '');
					this.$router.push('/app/cooperation');
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '拒绝邀请',
					});
					localStorage.setItem('yqGuid', '');
				});
		},
		// async showPay() {
		// 	this.payDialogVisible = true;
		// },

		async submitModifyUserInfo() {
			this.modifyDialogVisible = false;
			let r = await updateUserInfo({
				headImgUrl: this.modifyHeaderImg,
				nickname: this.modifyNickname,
			});
			if (r && this.$message({ message: '修改成功', type: 'success' })) {
				await getUserInfo();
				this.session = getSession();
			}
		},

		logout() {
			removeToken();
			this.$router.push('/');
		},

		showModifyUser() {
			this.modifyDialogVisible = true;
			this.modifyNickname = this.session.nickname;
			this.modifyHeaderImg = this.session.headImgUrl;
		},

		handleAvatarSuccess({ code, data }) {
			if (code == 0) {
				this.modifyHeaderImg = data;
			}
			this.uploading = false;
		},

		beforeAvatarUpload() {
			this.uploading = true;
		},
	},
};
</script>

<style lang="scss">
.layout-header {
	border-bottom: 1px solid #e5e5e5;
	overflow: hidden;
	position: fixed;
	z-index: 999;
	height: 30px;
	background-color: #fafafa;
	width: 100%;
	--wails-draggable: drag;
	padding: 5px 10px;
	display: flex;
	justify-content: space-between;
	align-content: center;
	align-items: center;
	top: 0;

	.btn-package {
		margin-right: 6px;
	}

	.logo {
		text-align: center;
		font-size: 18px;
		background: linear-gradient(to right, red, blue);
		-webkit-background-clip: text;
		color: transparent;
	}

	.header-img {
		border-radius: 100%;
		width: 20px;
		height: 20px;
	}

	.user-name {
		font-size: 12px;
	}

	.user-dropdown {
		position: relative;
		background: var(--primary-light);
		border-radius: 30px;
		color: var(--primary);
		cursor: pointer;
		border: 2px solid var(--primary);
		padding: 3px 10px 3px 6px;
	}

	.el-image__placeholder {
		display: none !important;
	}
}

.user-center-menu {
	width: 300px;
	position: relative;

	button.pay {
		border-radius: 30px;
		font-size: 10px;
		padding: 4px 8px 4px 6px;
	}

	.header-img-large {
		border-radius: 100%;
		width: 90px;
		height: 90px;
		border: solid 3px var(--primary);
	}

	button {
		font-weight: normal;
		margin-left: 10px;
	}

	.logout {
		margin-top: 10px;

		&:hover {
			background: white !important;
		}

		button {
			margin: 0;
			width: 100%;
			padding: 4px 15px;
		}
	}

	.btn-edit {
		//position: absolute;
		//top:10px;
		//right:10px;
		font-size: 12px;
		color: var(--primary);
	}
}

#modify-user {
	.header-img {
		border-radius: 100%;
		width: 80px;
		height: 80px;
	}

	.uploading {
		border-radius: 100%;
		width: 80px;
		height: 80px;
		font-size: 20px;
		color: var(--primary);
		background: #ede4fe66;
		position: absolute;
		top: 0;
		//border: 1px solid var(--primary);
	}
}
</style>
