<template>
	<el-aside width="76px" class="layout-sider">
		<div>
			<div class="menus">
				<div class="item" @click="goLink('chat')" :class="pageName == 'chat' ? 'active' : ''">
					<div class="icon" v-html="svgIcons.chat"></div>
					<div class="text">AI 对话</div>
				</div>

				<div class="item" @click="goLink('draw')" :class="pageName == 'draw' ? 'active' : ''">
					<div class="icon" v-html="svgIcons.draw"></div>
					<div class="text">AI 绘画</div>
				</div>

				<div class="item" @click="goLink('gallery')" :class="pageName == 'gallery' ? 'active' : ''">
					<div class="icon" v-html="svgIcons.image"></div>
					<div class="text">画廊</div>
				</div>
				<div class="item" @click="goLink('tools')" :class="pageName == 'tools' ? 'active' : ''">
					<div class="icon" v-html="svgIcons.tools"></div>
					<div class="text">AI智能体</div>
				</div>
				<div class="item" @click="goLink('cooperation')" :class="pageName == 'cooperation' ? 'active' : ''">
					<div class="icon" v-html="svgIcons.cooper"></div>
					<div class="text">协作空间</div>
				</div>
				<div class="item" v-for="(url, idx) of urls" :key="idx">
					<div class="icon">
						<a target="_blank" :href="url.urlLink">
							<img :src="url.urlIcon" style="width: 32px; height: 32px" />
						</a>
					</div>
					<div class="text">{{ url.urlTitle }}</div>
				</div>
			</div>
		</div>
	</el-aside>
</template>

<script>
import { svgIcons } from '@/libs/svgicon';
import { getMerchantPcToolsUrls } from '@/apis/chat';

export default {
	name: 'layout-sider',

	async mounted() {
		this.urls = await getMerchantPcToolsUrls();
	},

	data() {
		let pageName = this.$route.name;
		return {
			urls: [],
			svgIcons,
			pageName,
		};
	},

	methods: {
		goLink(key) {
			this.$router.push('/app/' + key);
		},
	},

	watch: {
		'$route.name': function (newName, oldName) {
			// 这里写你的逻辑
			this.pageName = newName;
		},
	},
};
</script>

<style lang="scss">
.layout-sider {
	border-right: solid 1px var(--border-color);
	background: #fafafa;
	padding-top: 10px;
	padding-right: 4px;
	height: 100%;
	width: 80px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	float: left;
	z-index: 10;

	.menus {
		.item {
			&:hover {
				color: var(--primary);
				background: var(--primary-light);
				border: 2px solid var(--primary);
			}

			&.active {
				color: var(--primary);
				background: var(--primary-light);
				border: 2px solid var(--primary);
			}

			color: var(--black);
			border: 2px solid var(--border-color);
			height: 54px;
			width: 60px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			cursor: pointer;
			background: var(--btn-bg-color);
			border-radius: 6px;
			margin-bottom: 10px;
			font-size: 12px;
			.icon img {
				border-radius: 50%;
			}
		}
	}
}
</style>
