<template>
	<el-main class="layout-chat">
		<el-container>
			<layout-cooperation-left
				@changeActiveId="createChat"
				@exitCooperation="helpLunciList"
				:_chatList="chatList"
				:_chatingId="guid"
			></layout-cooperation-left>
			<layout-cooperation-main
				:_chatObj="chatObj"
				:_chatingId="guid"
				:_chatDialogs="chatDialogs"
				@clearChatDialogs="clearChatDialogs"
			>
			</layout-cooperation-main>
		</el-container>
	</el-main>
</template>

<script>
import LayoutCooperationLeft from '@/views/app/layout-cooperation/layout-cooperation-left.vue';
import LayoutCooperationMain from '@/views/app/layout-cooperation/layout-cooperation-main.vue';
import { helpLunciList } from '@/apis/user';
import { getChatDialogs } from '@/apis/chat';
export default {
	name: 'layout-tools',
	components: { LayoutCooperationLeft, LayoutCooperationMain },

	async mounted() {},

	data() {
		return {
			chatList: [],
			guid: '',
			chatDialogs: [],
		};
	},

	methods: {
		createChat(id) {
			this.guid = id;
		},
		async helpLunciList() {
			let res = await helpLunciList();
			this.chatList = res.data;
			if (res.data.length > 0) {
				this.guid = res.data[0].chatLunciGuid;
			}
		},
		clearChatDialogs() {
			this.chatDialogs = [];
		},
	},
	mounted() {
		this.helpLunciList();
	},
	computed: {
		chatObj() {
			let obj = this.chatList.find(item => item.chatLunciGuid == this.guid);
			return obj || null;
		},
	},
	watch: {
		async guid(id) {
			this.chatDialogs = await getChatDialogs({ chatLunciGuid: id });
		},
	},
};
</script>

<style lang="scss">
.layout-draw {
	//border-right: solid 1px var(--border-color);
	padding: 0 !important;
}
</style>
