<template>
	<div class="layout-chat-main">
		<div class="chat-header" v-if="_chatObj">
			<span>{{ _chatObj.chatLunciTitle }}</span>
			<el-button @click="onInvitation" size="mini" round class="ml10 invitation-btn" style="padding: 5px 10px">
				<i class="iconfont el-icon-share mr4"></i> 邀请协助
			</el-button>
		</div>

		<!--        _chatDialogs : {{ _chatDialogs }}-->

		<chat-box id="chatbox" ref="chatBox" :_chatDialogs="_chatDialogs"></chat-box>

		<div class="chat-input">
			<div class="opt-panel mb4">
				<el-dropdown trigger="click" class="mr4">
					<el-button size="mini" round class="el-dropdown-link" style="padding: 6px 10px; margin-bottom: 0">
						<i class="iconfont icon-setting mr4"></i> 选择AI模型 ：{{ selectModel.name }}
					</el-button>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item
							@click.native="choooseModel(value)"
							v-for="{ name, value } of models"
							:key="value"
							>{{ name }}
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>

				<el-dropdown trigger="click" class="mr4">
					<el-button round class="el-dropdown-link" style="padding: 6px 10px; margin-bottom: 0">
						<i class="iconfont icon-robot2 mr4"></i> 选择AI角色<span v-if="selectRole.type === 'base'"
							>：{{ selectRole.name }}</span
						>
					</el-button>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item v-for="item of baseRoles" :key="item.id">
							<div class="flex role-drop-box">
								<div class="role-drop-title mr-4" @click="choooseRole(item, 'base')">
									{{ item.name }}
								</div>
							</div>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<el-dropdown trigger="click" class="mr4">
					<el-button round class="el-dropdown-link" style="padding: 6px 10px; margin-bottom: 0">
						<i class="iconfont icon-robot2 mr4"></i> 选择AI角色<span v-if="selectRole.type === 'xz'"
							>：{{ selectRole.name }}</span
						>
					</el-button>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item v-for="item of roles" :key="item.id">
							<div class="flex role-drop-box">
								<div class="role-drop-title mr-4" @click="choooseRole(item, 'xz')">
									{{ item.name }}
								</div>
								<el-button
									size="mini"
									icon="el-icon-edit"
									v-if="item.isEdit"
									@click="onEditRole(item)"
								></el-button>
								<el-button
									size="mini"
									icon="el-icon-delete"
									v-if="item.isDelete"
									@click="onDeleteRole(item)"
								></el-button>
							</div>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>

				<el-button @click="cleanDialogs" size="mini" round class="mr4">
					<i class="iconfont el-icon-brush mr4"></i> 清除聊天记录
				</el-button>

				<!--                <el-upload class="ml4 mr16" :show-file-list="false" name="img"-->
				<!--                           action="https://ai-v2.deepcity.cn/user/api.userinfo/uploadImg"-->
				<!--                           :on-success="uploadSuccess" :before-upload="beforeUpload" :limit="3"-->
				<!--                           :on-exceed="handleExceed">-->

				<!--                    <el-badge :value="msgImgsCount" v-if="msgImgsCount>0">-->
				<!--                        <el-button :disabled="!isSupportImg" @click="" size="mini" round style="padding: 5px 10px;">-->
				<!--                            <i class="el-icon-upload2"></i> 图像-->
				<!--                        </el-button>-->
				<!--                    </el-badge>-->

				<!--                    <el-button  :disabled="!isSupportImg" @click="" size="mini" round style="padding: 5px 10px;" v-if="msgImgsCount==0">-->
				<!--                        <i class="el-icon-upload2"></i> 图像-->
				<!--                    </el-button>-->

				<!--                </el-upload>-->

				<el-button
					:disabled="!isSupportImg"
					v-if="msgImgsCount == 0"
					size="mini"
					round
					style="padding: 5px 10px"
					class="ml4"
					@click="imgInputVisible = true"
				>
					<i class="el-icon-upload2"></i> 图像
				</el-button>

				<el-badge :value="msgImgsCount" v-if="msgImgsCount > 0" class="ml4">
					<el-button
						:disabled="!isSupportImg"
						size="mini"
						round
						style="padding: 5px 10px"
						@click="imgInputVisible = true"
					>
						<i class="el-icon-upload2"></i> 图像
					</el-button>
				</el-badge>

				<el-button type="success" style="margin-left: auto" class="ml4" size="mini" @click="waitingImage">
					<i class="el-icon-picture mr4"></i> 绘画
				</el-button>
			</div>

			<div class="flex">
				<div class="input-box">
					<!-- @keyup.enter.native="send" -->
					<el-input
						class="mr10"
						type="textarea"
						:rows="4"
						placeholder="请输入内容"
						v-model="textarea"
						resize="none"
					></el-input>
					<el-button
						size="mini"
						round
						class="el-dropdown-link"
						style="padding: 6px 10px; margin-bottom: 0"
						@click="isPrviewShow = true"
					>
						<i class="el-icon-full-screen"></i> 预览
					</el-button>
				</div>

				<div>
					<el-button type="primary" @click="send" class="send" size="small">发送</el-button>
					<!-- <div class="mt4">
						<el-button type="success" style="margin-left: auto" size="mini"
							>预览
						</el-button>
					</div> -->
				</div>
			</div>
		</div>

		<el-dialog title="多模态上传" :visible.sync="imgInputVisible" width="500px">
			<div class="center">
				<el-upload
					name="img"
					list-type="picture"
					:limit="3"
					:on-success="uploadSuccess"
					:before-remove="beforeRemove"
					:on-exceed="handleExceed"
					drag
					action="https://ai-v2.deepcity.cn/user/api.userinfo/uploadImg"
				>
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					<div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过5mb</div>
				</el-upload>
			</div>

			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="imgInputVisible = false">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="预览" :visible.sync="isPrviewShow" :close-on-click-modal="false" width="800px">
			<!-- @keyup.enter.native="send" -->
			<el-input type="textarea" :rows="20" placeholder="请输入内容" v-model="textarea"></el-input>
		</el-dialog>
	</div>
</template>

<script>
import ChatBox from '@/views/app/layout-chat/chat-box.vue';
import { clearLunci, genImage, getAbleModels, getRobotRoles, saveChatDialog, helpRoleList } from '@/apis/chat';

export default {
	name: 'layout-chat-main',
	components: { ChatBox },

	async created() {
		this.models = await getAbleModels({ is_all_pc: 1 });
		this.baseRoles = await getRobotRoles();
		this.models.push({ name: '通用AI模型', value: '' });
		this.baseRoles.push({ name: '通用对话角色', value: '' });
		this.getHelpRoleList();
	},

	props: {
		_chatDialogs: Array,
		_chatingId: String,
		_chatObj: Object,
	},

	data() {
		return {
			models: [],
			baseRoles: [],
			roles: [],
			selectModelKey: '',
			selectModel: { value: '', name: '通用AI模型', is_able_img: false },
			selectRoleKey: '',
			selectRole: { id: '', name: '通用对话角色', type: 'base' },
			textarea: '',
			msg: [],
			isNewGlobalMsg: false,

			// 图片
			msgImgs: [],
			imgInputVisible: false,
			isPrviewShow: false,
			isHelp: false,
		};
	},

	computed: {
		msgImgsCount() {
			return this.msgImgs.length;
		},

		// 支持图片
		isSupportImg() {
			return this.selectModel.is_able_img;
		},
	},

	methods: {
		uploadSuccess({ code, data }) {
			if (code == 0) {
				this.msgImgs.push(data);
			}
		},
		async getHelpRoleList() {
			let res = await helpRoleList({ chatLunciGuid: this._chatingId });
			let list = [];
			this.helpList = res.data;
			res.data.forEach(item => {
				let obj = {
					id: item.sysId,
					name: item.showRoleName,
					isHelp: true,
					isDelete: item.isDelete,
					isEdit: item.isEdit,
				};
				list.push(obj);
			});
			// this.roles = this.baseRoles.concat(list);
			this.roles = list;
		},
		onInvitation() {
			let guid = this._chatObj.guid;
			let yqUrl = `https://xy.deepcity.cn/app/chat?yqGuid=${guid}`;
			this.$copyText(yqUrl);
			this.$message({ message: '已复制邀请链接', type: 'success' });
		},

		beforeRemove({ response }) {
			this.msgImgs = this.msgImgs.filter(item => item != response.data);
		},

		handleExceed() {
			alert('exceed ...');
		},

		choooseModel(value) {
			this.selectModelKey = value;
			this.selectModel = this.models.find(item => item.value == value);
		},

		choooseRole(role, type) {
			//type xz为协助角色  base为 默认ai角色
			if (role.isHelp) {
				this.isHelp = true;
			}
			this.selectRoleKey = role.id;
			if (type === 'xz') {
				this.selectRole = this.roles.find(item => item.id == role.id);
			} else {
				this.selectRole = this.baseRoles.find(item => item.id == role.id);
			}
			this.selectRole.type = type;
		},

		cleanDialogs() {
			// this._chatDialogs = []
			this.$emit('clearChatDialogs');
			clearLunci(this._chatingId);
		},

		async send() {
			if (!this.textarea) return;

			let content = this.textarea;
			this.textarea = '';

			let lastMsgId = '';
			let len = this._chatDialogs.length;
			if (len > 0) {
				lastMsgId = this._chatDialogs[len - 1].msgId;
			}

			let saveMsgId = await saveChatDialog({
				content,
				lastMsgId,
				chatLunciGuid: this._chatingId,
				imgs: this.msgImgs,
				model: this.selectModelKey,
			});

			this._chatDialogs.push({ msgId: 'new', chatRole: 'user', chatContent: content, imgUrls: this.msgImgs });
			this._chatDialogs.push({ msgId: 'new', chatRole: 'assistant', chatContent: '', starting: true });

			this.msgImgs = [];

			this.toBottom();

			await this.waitChat(saveMsgId);

			return false;
		},

		// 滚动到最底部
		toBottom() {
			this.$nextTick(() => {
				let scrollDom = document.getElementById('chatbox');
				scrollDom.scrollTop = scrollDom.scrollHeight;
			});
		},

		async waitChat(lastMsgId) {
			let dialog = this._chatDialogs[this._chatDialogs.length - 1];
			let self = this;

			let link = `https://ai-v2.deepcity.cn/square/api.chat/sendOpen?roleId=${this.selectRoleKey}&model=${
				this.selectModelKey
			}&msgId=${lastMsgId}&roleType=${this.isHelp ? '2' : '1'}`;

			console.log('selectModelKey : ', link);

			const source = new EventSource(link);
			source.onmessage = async function ({ data }) {
				data && (dialog.starting = false);

				if (data == '[DONE]') {
					dialog.msgId = await saveChatDialog({
						role: 'assistant',
						content: dialog.chatContent,
						lastMsgId,
						chatLunciGuid: self._chatingId,
						model: self.selectModelKey,
					});
					source.close();
				} else {
					dialog.chatContent += data.replace(/\\n/g, '\n');
				}
			};

			source.onopen = function (event) {
				console.log('Connection was opened');
				// dialog.starting = false
			};

			source.onerror = async function (event) {
				// console.log('event : ', event)
				// dialog.chatContent += '[ ERROR ]'
				source.close();
			};
		},

		// 请求图片 , imgUrls
		async waitingImage() {
			// let r = await genImage(this.textarea)
			//
			// console.log('r:', r)

			if (!this.textarea) return;

			let content = this.textarea;
			this.textarea = '';

			let lastMsgId = '';
			let len = this._chatDialogs.length;

			if (len > 0) {
				lastMsgId = this._chatDialogs[len - 1].msgId;
			}

			let saveMsgId = await saveChatDialog({
				content,
				lastMsgId,
				chatLunciGuid: this._chatingId,
				imgs: this.msgImgs,
				model: this.selectModelKey,
			});

			this._chatDialogs.push({ msgId: 'new', chatRole: 'user', chatContent: content, imgUrls: this.msgImgs });
			this._chatDialogs.push({ msgId: 'new', chatRole: 'assistant', chatContent: '', starting: true });

			this.msgImgs = [];

			this.toBottom();

			let url = await genImage(content);

			if (url) {
				let dialog = this._chatDialogs[this._chatDialogs.length - 1];

				dialog.starting = false;
				dialog.imgUrls = [url];

				dialog.msgId = await saveChatDialog({
					role: 'assistant',
					content: dialog.chatContent,
					lastMsgId,
					chatLunciGuid: this._chatingId,
					model: this.selectModelKey,
					imgs: [url],
				});
			}
		},
	},
};
</script>

<style scoped lang="scss">
.role-drop-box {
	margin-bottom: 10px;
	.role-drop-title {
		width: 120px;
		margin-right: 10px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}
::v-deep .el-dropdown-menu__item i {
	margin-right: 0 !important;
}
.invitation-btn {
	margin-left: 10px;
	display: flex;
	align-items: center;
}
.input-box {
	flex: 1;
	position: relative;
	.el-dropdown-link {
		position: absolute;
		right: 4px;
		bottom: 4px;
		z-index: 9;
	}
	margin-right: 20px;
}
::v-deep .el-dialog__body {
	padding: 10px 20px 20px 20px;
}
.layout-chat-main {
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	flex: 1;

	.chat-header {
		border-bottom: 1px solid var(--border-color);
		padding: 14px 20px;
		display: flex;
		align-items: center;
	}

	.chat-input {
		border-top: 1px solid var(--border-color);
		padding: 10px 20px;
	}

	.chat-box {
		flex: 1 1;
		overflow: auto;
		padding: 10px 0;
	}

	.send {
		height: 40px;
		width: 80px;
	}

	.opt-panel {
		margin-bottom: 10px;
		display: flex;

		.el-button {
			padding: 2px 11px;
			font-size: 12px;
			font-weight: normal;

			i {
				font-size: 12px;
			}
		}
	}
}
</style>
